@import 'variables/colors';


@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutFromRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.container {
  background-color: $primary-color-1;
  position: absolute;
  height: 100vh;
  padding: 20px 0;
  top: 0;
  left: 0;
  width: 60%;
  z-index: 100;

  &.hide {
    display: none;
  }

  &.fade-in {
    animation: slideInFromLeft 0.5s ease-out;
  }

  &.fade-out {
    animation: slideOutFromRight 0.5s ease-out;
  }
}

.option {
  text-align: start;
  padding: 10px 20px;

  &::before {
    content: '>';
    color: $white;
    margin: 0 10px 8px 0;
  }

  & > span {
    border-bottom: 2px solid $white;
    padding-bottom: 4px;
  }
}