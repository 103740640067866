@import 'variables/colors';

// stylelint-disable no-descending-specificity

%button {
  align-items: center;
  border: 1px solid;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.1em; // stylelint-disable-line declaration-property-unit-disallowed-list
  line-height: 24px;
  padding: 8px 16px;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
  width: fit-content;

  .icon {
    margin-right: 8px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .text {
    font-size: 14px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
  }

  &::after {
    content: '';
    border-radius: 0;
    display: block;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: height 0.8s, width 0.8s, opacity 0.8s, border-radius 0.8s;
    width: 100%;
  }

  &:active::after {
    border-radius: 100%;
    height: 0;
    opacity: 1;
    transition: 0s;
    width: 0;
  }
}

%button-small {

  @extend %button;
  font-size: 12px;
  height: 32px;
  line-height: 16px;
  padding: 8px;

  .text {
    font-size: 12px;
    height: 16px;
    line-height: 16px;
  }
}

.button-primary,
.button-primary-small {

  @extend %button;
  background-color: $primary-color-2;
  border-color: $primary-color-2;
  color: $neutral-color-6;

  .icon {
    > path {
      fill: $neutral-color-6;
    }
  }

  .text {
    color: $neutral-color-6;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-color-2;
    border-color: $primary-color-2;
  }

  &::after {
    background-color: $primary-color-2;
  }

  &:disabled {
    background-color: $neutral-color-3;
    border-color: $neutral-color-3;
    cursor: auto;

    &:hover {
      background-color: $neutral-color-3;
      border-color: $neutral-color-3;
    }
  }
}

.button-primary-small {

  @extend %button-small;
}

.button-primary-cta,
.button-primary-cta-small {

  @extend .button-primary; // stylelint-disable-line scss/at-extend-no-missing-placeholder
  background-color: $secondary-color-1;
  border-color: $secondary-color-1;

  &:hover,
  &:focus,
  &:active {
    background-color: $secondary-color-2;
    border-color: $secondary-color-2;
  }

  &::after {
    background-color: $secondary-color-1;
  }
}

.button-primary-cta-small {

  @extend %button-small;
}

.button-secondary,
.button-secondary-small {

  @extend %button;
  background-color: transparent;
  border-color: $primary-color-2;
  color: $primary-color-2;

  .icon {
    > path {
      fill: $primary-color-2;
    }
  }

  .text {
    color: $primary-color-2;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: $primary-color-2;
    color: $primary-color-2;

    .icon {
      path {
        fill: $primary-color-2;
      }
    }

    .text {
      color: $primary-color-2;
    }
  }

  &::after {
    background-color: $primary-color-2;
  }

  &:disabled {
    border-color: $neutral-color-3;
    color: $neutral-color-3;
    cursor: auto;

    &:hover {
      border-color: $neutral-color-3;
      color: $neutral-color-3;
    }
  }
}

.button-secondary-small {

  @extend %button-small;
}

.button-secondary-white,
.button-secondary-white-small {

  @extend .button-secondary; // stylelint-disable-line scss/at-extend-no-missing-placeholder
  border-color: $neutral-color-6;
  color: $neutral-color-6;

  .icon {
    > path {
      fill: $neutral-color-6;
    }
  }

  .text {
    color: $neutral-color-6;
  }

  &::after {
    background-color: $neutral-color-6;
  }
}

.button-secondary-white-small {

  @extend %button-small;
}

.button-filter {
  align-items: center;
  display: flex;
  padding: 8px 0;

  .text {
    color: $neutral-color-2;
  }

  .icon {
    path {
      fill: $neutral-color-2;
    }
  }

  &.active {
    .text {
      color: $primary-color-2;
      font-weight: 900;
    }

    .icon {
      path {
        fill: $primary-color-2;
      }
    }
  }
}

.button-pill {
  align-items: center;
  border: 1px solid $neutral-color-2;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  height: fit-content;
  padding: 4px 8px;

  .icon {
    margin-left: 4px;

    path {
      fill: $neutral-color-2;
    }
  }

  &:hover {
    border-color: $primary-color-2;

    > .text {
      color: $primary-color-2;
    }

    > .icon {
      path {
        fill: $primary-color-2;
      }
    }
  }
}

@keyframes arrowDown {
  from,
  to {
    transform: translateY(0);
  }

  50% {
    transform: translateY(5px);
  }
}

@keyframes arrowUp {
  from,
  to {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

@keyframes arrowRight {
  from,
  to {
    transform: translateX(0);
  }

  50% {
    transform: translateX(5px);
  }
}

@keyframes arrowLeft {
  from,
  to {
    transform: translateY(0);
  }

  50% {
    transform: translateX(-5px);
  }
}

.arrow-effect {
  &.arrow-right,
  .arrow-right,
  &.arrow-down,
  .arrow-down,
  &.arrow-left,
  .arrow-left,
  &.arrow-up,
  .arrow-up {
    polygon {
      fill: $primary-color-2;
    }
  }

  &:hover {
    &.arrow-right,
    .arrow-right {
      animation: arrowRight 1s infinite;
    }

    &.arrow-down,
    .arrow-down {
      animation: arrowDown 1s infinite;
    }

    &.arrow-left,
    .arrow-left {
      animation: arrowLeft 1s infinite;
    }

    &.arrow-up,
    .arrow-up {
      animation: arrowUp 1s infinite;
    }
  }

  &:disabled {
    &.arrow-right,
    &.arrow-down,
    &.arrow-left,
    &.arrow-up {
      animation: unset;
    }
  }
}
