@import 'variables/colors';

.unlabeled-field {
  margin-top: 30px;
}

.full-screen-height {
  height: 100vh;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.hidden {
  visibility: hidden;
}

.line-decorator {
  align-items: center;
  color: $neutral-color-2;
  display: flex;
  flex-direction: row;
  width: 100%;

  &::before,
  &::after {
    background-color: $neutral-color-4;
    content: '';
    height: 1px;
    width: 100%;
  }

  &::before {
    margin-right: 12px;
  }

  &::after {
    margin-left: 12px;
  }
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.hide {
  display: none;
}

.show-sm {
  display: none;
}

.navbar-offset {
  padding-top: $navbar-height;

  @media screen and (max-width: $small-screen) {
    padding-top: 0;
  }
}

.content {
  padding: 0 $horizontal-padding;
  width: 100%;

  @media screen and (max-width: $small-screen) {
    padding: 0 5%;
  }
}

.scroll {
  overflow: auto;
}

@media screen and (max-width: $small-screen) {
  .hide-sm {
    display: none;
  }

  .show-sm {
    display: flex;
  }

  .full-width-sm {
    width: 100%;
  }
}

.show-transition-off {
  transform: translateY(100px);
  transition: transform 1s, opacity 1s;
  opacity: 0;
  
  &.show-transition-on {
    opacity: 1;
    transform: translateY(0);
  }
}

.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}