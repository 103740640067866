@import 'variables/colors', 'variables/sizes';

@import 'layout';

@import 'margins';

@import 'paddings';

@import 'texts';

@import 'buttons';

@import 'components';

@import 'assets';

@import 'screens';

$equalizer-black: #333;
$equalizer-grey: #999;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto Condensed', sans-serif;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

}

button {
  &:disabled {
    cursor: auto;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
input,
a,
button,
select,
textarea {
  color: $equalizer-black;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin: 0;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

input,
select,
textarea,
button {
  border: 0;
  border-radius: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  background: transparent;
  cursor: pointer;
}

button::-moz-focus-inner {
  border: 0;
}

a {
  cursor: pointer;
  text-decoration: none;

  &:focus,
  &:active {
    color: $equalizer-black;
    text-decoration: none;
  }
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

::placeholder {
  color: $equalizer-grey;
  opacity: 1;
}

b,
strong {
  font-weight: normal;
}
