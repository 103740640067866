// stylelint-disable selector-class-pattern

.audiowide {
  font-family: 'Audiowide', cursive;
  font-style: oblique;
}

%industry {
  color: $primary-color-1;
  font-weight: 900;
  letter-spacing: 0.05em; // stylelint-disable-line declaration-property-unit-disallowed-list
  text-align: start;
  text-transform: uppercase;
  white-space: nowrap;
}

.header-h1-extra-large {

  @extend %industry;
  font-size: 90px;
  letter-spacing: 0.1em; // stylelint-disable-line declaration-property-unit-disallowed-list
  line-height: 80px + 6px;
}

.header-h1 {

  @extend %industry;
  font-size: $header-h1;
  letter-spacing: 0.1em; // stylelint-disable-line declaration-property-unit-disallowed-list
  line-height: $header-h1 + 6;
}

.header-h2 {

  @extend %industry;
  font-size: $header-h2;
  line-height: $header-h2 + 6;
}

.header-h3 {

  @extend %industry;
  font-size: $header-h3;
  line-height: $header-h3 + 2;
}

.header-h4 {

  @extend %industry;
  font-size: $header-h4;
  font-weight: 700;
  line-height: $header-h4 + 4;
}

.header-h5 {
  color: $primary-color-2;
  font-size: $header-h5;
  font-weight: 900;
  letter-spacing: 0.1em; // stylelint-disable-line declaration-property-unit-disallowed-list
  line-height: $header-h5 + 8;
  text-transform: uppercase;
}

.header-h6 {
  color: $neutral-color-1;
  font-size: $header-h6;
  font-weight: 700;
  letter-spacing: 0.1em; // stylelint-disable-line declaration-property-unit-disallowed-list
  line-height: $header-h6 + 10;
  text-transform: uppercase;
}

.text-large {
  color: $neutral-color-2;
  font-size: $text-large;
  line-height: $text-large + 14;
}

.text-normal {
  color: $neutral-color-2;
  font-size: $text-normal;
  line-height: $text-normal + 8;
}

.nav,
.nav-invert {
  cursor: pointer;
  font-size: $nav;
  font-weight: 700;
  line-height: $nav + 10;
  white-space: nowrap;
}

.nav {
  color: $primary-color-2;

  > .icon {
    path {
      fill: $primary-color-2;
    }
  }

  &:hover {
    color: $primary-color-2;

    > .icon {
      path {
        fill: $primary-color-2;
      }
    }
  }

  &.active {
    color: $primary-color-2;
    font-weight: 900;

    &.with-border {
      border-bottom: 2px solid $primary-color-2;
    }
  }
}

.nav-invert {
  color: $primary-color-2;

  > .icon { // stylelint-disable-line no-descending-specificity
    path { // stylelint-disable-line no-descending-specificity
      fill: $primary-color-2;
    }
  }

  &:hover {
    color: $primary-color-2;

    > .icon {
      path {
        fill: $primary-color-2;
      }
    }
  }

  &.active {
    color: $primary-color-2;
    font-weight: 900;

    &.with-border {
      border-bottom: 2px solid $primary-color-2;
    }
  }
}

.link {
  color: $primary-color-2;
  cursor: pointer;
  font-size: $link;
  font-weight: 700;
  line-height: $link + 8;
  white-space: nowrap;

  .text {
    color: $primary-color-2;
    font-size: $link;
    font-weight: 700;
    line-height: $link + 8;
    white-space: nowrap;
  }

  .icon path { // stylelint-disable-line no-descending-specificity
    fill: $primary-color-2;
  }

  &.active {
    color: $primary-color-2;
    font-weight: 900;

    .text {
      color: $primary-color-2;
      font-weight: 900;
    }

    .icon {
      path {
        fill: $primary-color-2;
      }
    }
  }

  &.underline {
    text-decoration: underline;
  }

  &:hover {
    color: $primary-color-2;

    .text {
      color: $primary-color-2;
    }

    .icon {
      path {
        fill: $primary-color-2;
      }
    }
  }

  &:focus {
    color: $primary-color-2;

    .text {
      color: $primary-color-2;
    }

    .icon {
      path {
        fill: $primary-color-2;
      }
    }
  }

  &:disabled {
    color: $neutral-color-3;

    .text {
      color: $neutral-color-3;
    }

    &:hover {
      color: $neutral-color-3;

      .icon path {
        fill: $neutral-color-3;
      }

      .text {
        color: $neutral-color-3;
      }
    }
  }
}

.search-type {
  color: $neutral-color-2;
  font-size: $search-type;
  line-height: $search-type + 10;
}

.button-text {

  @extend %industry;
  font-size: $button;
  letter-spacing: 0.1em; // stylelint-disable-line declaration-property-unit-disallowed-list
  line-height: $button + 10;
}

.button-text-small {

  @extend %industry;
  font-size: $button-small;
  line-height: $button-small + 4;
}

.text-small {
  color: $neutral-color-2;
  font-size: $text-small;
  line-height: $text-small + 4;
}

.bread-crumb {
  color: $neutral-color-2;
  font-size: $bread-crumb;
  font-weight: 700;
  letter-spacing: 0.1em; // stylelint-disable-line declaration-property-unit-disallowed-list
  line-height: $bread-crumb + 12;
  text-transform: uppercase;
}

.sub-head {
  color: $neutral-color-3;
  font-size: $sub-head;
  font-weight: 900;
  letter-spacing: 0.1em; // stylelint-disable-line declaration-property-unit-disallowed-list
  line-height: $sub-head + 12;
  text-transform: uppercase;
}

.listing-type {
  color: $neutral-color-2;
  font-size: $listing-type;
  letter-spacing: 0.1em; // stylelint-disable-line declaration-property-unit-disallowed-list
  line-height: $listing-type + 6;
  text-transform: uppercase;
}

.mouse-type {
  color: $neutral-color-2;
  font-size: $mouse-type;
  line-height: $mouse-type + 6;
}

@media screen and (max-width: $small-screen) {
  .header-h1 {
    font-size: $header-h1-sm;
    line-height: $header-h1-sm + 9;
    white-space: normal;
  }

  .header-h2 {
    font-size: $header-h2-sm;
    line-height: $header-h2-sm + 7;
    white-space: normal;
  }

  .header-h3 {
    font-size: $header-h3-sm;
    line-height: $header-h3-sm + 4;
    white-space: normal;
  }

  .header-h4 {
    font-size: $header-h4-sm;
    line-height: $header-h4 + 7;
    white-space: normal;
  }

  .header-h5 {
    font-size: $header-h5-sm;
    line-height: $header-h5-sm + 10;
  }

  .text-normal {
    font-size: $text-normal-sm;
    line-height: $text-normal-sm + 10;
  }

  .link {
    font-size: $link-sm;
    line-height: $link-sm + 10;
  }

  .sub-head {
    font-size: $sub-head-sm;
    line-height: $sub-head-sm + 6;
  }

  .mouse-type-sm {
    color: $neutral-color-2;
    font-size: $mouse-type;
    line-height: $mouse-type + 6;
  }

  .search-result-sm {
    font-weight: 700;
    font-size: $search-result-sm;
    line-height: $search-result-sm + 10;
    letter-spacing: 0.05em; // stylelint-disable-line declaration-property-unit-disallowed-list
    text-transform: uppercase;
    color: $primary-color-2;
  }

  .text-normal-sm {
    font-size: $text-normal;
    line-height: $text-normal + 2px;
  }

  .text-small-sm {
    font-size: $text-small;
    line-height: $text-small + 4px;
  }

  .header-h1-sm {
    font-size: $header-h1-sm;
    line-height: $header-h1-sm + 9;
    white-space: normal;
  }
}

.primary-color-1 {
  color: $primary-color-1;
}

.primary-color-2 {
  color: $primary-color-2;
}

.primary-color-3 {
  color: $primary-color-3;
}

.secondary-color-1 {
  color: $secondary-color-1;
}

.secondary-color-2 {
  color: $secondary-color-2;
}

.neutral-color-1 {
  color: $neutral-color-1;
}

.neutral-color-2 {
  color: $neutral-color-2;
}

.neutral-color-3 {
  color: $neutral-color-3;
}

.neutral-color-5 {
  color: $neutral-color-5;
}

.neutral-color-6 {
  color: $neutral-color-6;
}

.white {
  color: $white;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-start {
  text-align: start;
}

.submit-error {
  height: 0;
  transform: translateY(-32px);
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.pre-space {
  white-space: pre;
}

.text-no-wrap {
  white-space: nowrap;
}

.italic {
  font-style: italic;
}

.slim {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.black-bold {
  font-weight: 800;
}

.ultra-bold {
  font-weight: 900;
}

.letter-spacing-1 {
  letter-spacing: 0.1em; // stylelint-disable-line declaration-property-unit-disallowed-list
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.word-wrap {
  word-wrap: break-word;
}

.title-decorator {
  position: relative;

  &::before {
    background-color: $primary-color-2;
    content: '';
    height: 4px;
    left: -20px;
    position: absolute;
    top: -12px;
    width: 40px;
  }

  @media screen and (max-width: $small-screen) {
    &::before {
      left: -8px;
      top: -4px;
      width: 24px;
    }
  }
}

.break {
  white-space: break-spaces;
}

@media screen and (max-width: $small-screen) {
  .text-align-center-sm {
    text-align: center;
  }
}
