@import 'variables/colors', 'variables/sizes';

.container {
  align-items: stretch;
  background-color: $secondary-color-1;
  padding: 20px 50px 32px;
  position: relative;
  width: 100%;
}

.wave {
  max-height: 152px;
  left: 0;
  position: absolute;
  top: 2px;
  transform: translateY(-100%);
  width: 100%;
}

.social-media {
  border: 2px solid $secondary-color-1;
  border-radius: 100%;
  height: 46px;
  padding: 10px;
  width: 46px;

  &:hover {
    border-color: $neutral-color-6;
  }
}