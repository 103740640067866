@import 'variables/colors';

.container {
  background-color: transparent;
  padding: 24px 50px 0;
  position: fixed;
  transition: background-color 1s ease;
  width: 100%;
  z-index: 10;

  > :nth-child(n) {
    transition: color 1s ease, opacity 1s ease;
  }

  .wave {
    opacity: 0;
    max-height: 75px;
    left: 0;
    position: absolute;
    bottom: 1px;
    transform: translateY(100%);
    width: 100%;
  }

  &.white {
    background-color: #FFF;

    > :nth-child(n) {
      color: $primary-color-1;
    }

    .wave {
      opacity: 1;
    }
  }
}

.logo {
  height: 48px;
}
