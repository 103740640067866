.icon-12 {
  height: 12px;
  width: 12px;
}

.icon-16 {
  height: 16px;
  width: 16px;
}

.icon-20 {
  height: 20px;
  width: 20px;
}

.icon-24 {
  height: 24px;
  width: 24px;
}

.icon-32 {
  height: 32px;
  width: 32px;
}

.icon-40 {
  height: 40px;
  width: 40px;
}

.icon-48 {
  height: 48px;
  width: 48px;
}

.icon-56 {
  height: 56px;
  width: 56px;
}

.icon-64 {
  height: 64px;
}

.icon-primary-color-2 {
  path {
    fill: $primary-color-2;
  }
}

.icon-neutral-color-2 {
  path {
    fill: $neutral-color-2;
  }
}

.icon-neutral-color-3 {
  path {
    fill: $neutral-color-3;
  }
}

.icon-neutral-color-5 {
  path {
    fill: $neutral-color-5;
  }
}

.icon-neutral-color-6 {
  path {
    fill: $neutral-color-6;
  }
}

@media screen and (max-width: $small-screen) {
  .icon-sm-12 {
    height: 12px;
    width: 12px;
  }

  .icon-sm-16 {
    height: 16px;
    width: 16px;
  }

  .icon-sm-20 {
    height: 20px;
    width: 20px;
  }

  .icon-sm-24 {
    height: 24px;
    width: 24px;
  }

  .icon-sm-32 {
    height: 32px;
    width: 32px;
  }

  .icon-sm-48 {
    height: 48px;
  }
}
