/* stylelint-disable scss/dollar-variable-pattern */

$white: #FFF;
$black: #000;
$red: #F00;

$primary-color-1: #374997;
$primary-color-2: #3E60C1;
$primary-color-3: #8CA2C8;
$secondary-color-1: #BE3339;
$secondary-color-2: #C67572;
$secondary-color-3: #DEA9AD;
$neutral-color-1: #313335;
$neutral-color-2: #666766;
$neutral-color-3: #AAAAAB;
$neutral-color-4: #DDDDDD;
$neutral-color-5: #F1F1F1;
$neutral-color-6: #F7F8FC;
$highlight: #FF0404;
$highlight-shadow: rgba(240, 0, 0, 0.6);


$green: #1687a7;
$green-dark: #289672;
$pink: #ff7171;
$violet: #693c72;
$orange: #e2703a;
$bordo: #9c3d54;
$yellow: #eeb76b;


$primary-color-transparent-1: rgba(55, 73, 151, 0.6);
$primary-color-transparent-2: rgba(62, 96, 193, 0.6);
$primary-color-transparent-3: rgba(140, 162, 200, 0.6);
$primary-color-transparent-4: rgba(6, 24, 104, 0.6);
$secondary-color-transparent-1: rgba(190, 51, 57, 0.6);
$secondary-color-transparent-2: rgba(198, 117, 114, 0.6);
$secondary-color-transparent-3: rgba(222, 169, 173, 0.6);
$secondary-color-transparent-4: rgba(83, 19, 25, 0.6);
