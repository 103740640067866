@import 'variables/colors', 'variables/sizes';

.fade-out {
  animation-name: fade-out;
  animation-duration: 1s;
}

@keyframes fade-out {
  0% {
    filter: alpha(opacity=1000);
    opacity: 100;
  }

  100% {
    filter: alpha(opacity=0);
    opacity: 0.0;
  }
}

.fade-in {
  animation-name: fade-in;
  animation-duration: 1s;
}

@keyframes fade-in {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
  }

  100% {
    filter: alpha(opacity=1000);
    opacity: 100;
  }
}

.screen-with-footer {
  min-height: 100vh;
  padding-bottom: $footer;

  @media screen and (max-width: $small-screen) {
    padding-bottom: $footer-responsive;
  }
}
