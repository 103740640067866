// Font sizes
/* stylelint-disable scss/dollar-variable-pattern */

$header-h1: 66px;
$header-h2: 42px;
$header-h3: 30px;
$header-h4: 20px;
$header-h5: 16px;
$header-h6: 14px;

$text-large: 18px;
$text-normal: 16px;
$text-small: 12px;

$link: 16px;
$nav: 14px;
$search-type: 14px;
$bread-crumb: 12px;
$sub-head: 12px;
$listing-type: 10px;
$mouse-type: 10px;

$button: 14px;
$button-small: 12px;

$header-h1-sm: 31px;
$header-h2-sm: 25px;
$header-h3-sm: 20px;
$header-h4-sm: 17px;
$header-h5-sm: 14px;

$text-normal-sm: 14px;

$link-sm: 14px;
$sub-head-sm: 10px;
$search-result-sm: 14px;

// Screen sizes

$content-max-width: 980px;
$extra-small-screen: 410px;
$small-screen: 1024px;
$medium-screen: 1680px;
$big-screen: 1920px;

// Navbar
$navbar-height: 96px;
$navbar-responsive-height: 64px;
$navbar-top-responsive-height: 64px;

$horizontal-padding: 10%;
$contact-box-size: 382px;

// Filters
$filters-height: 56px;
$filters-responsive-height: 64px;

// Logos

$big-logo-size: 152px;
$small-logo-size: 94px;
$responsive-logo-size: 72px;
$footer: 150px;
$footer-responsive: 50px;
