@import 'variables/colors';

.container {
  background-color: transparent;
  padding: 12px 20px 10px;
  position: fixed;
  transition: background-color 1s ease;
  width: 100%;
  z-index: 10;

  & > :nth-child(n) {
    transition: color 1s ease, opacity 1s ease;
  }

  &.white {
    background-color: white;

    & > :nth-child(n) {
      color: $primary-color-1;
    }

    .wave {
      opacity: 1;
    }
  }
}

.wave {
  opacity: 0;
  max-height: 16px;
  left: 0;
  position: absolute;
  bottom: 2px;
  transform: translateY(100%);
  width: 100%;
}

.logo {
  height: 36px;
}

.hamburger {
  flex: 1;

  > .hamburger-svg {
    height: 18px;

    &.white {
      path {
        fill: $white;
      }
    }
  }
}
